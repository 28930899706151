<template>
  <div class="profile__wrapper">
    <div class="categorisation categorisation__wrapper" v-if="structure">
      <div
        class="profile__section"
        :style="{ order: group.order_position }"
        v-for="(group, id_0) in structure"
        :key="`${group.uuid}${id_0}`"
      >
        <h4 class="title-h4 profile__title" v-if="!!group.title">
          {{ group.title }}
        </h4>
        <div
          class="categorisation__block"
          :style="{ order: subgroup.order_position }"
          v-for="(subgroup, id_1) in group.subgroups"
          :key="`${subgroup.uuid}${id_1}`"
        >
          <h5 class="title-h5 profile__subtitle" v-if="!!subgroup.title">
            {{ subgroup.title }}:
          </h5>
          <div
            :class="[
              subgroup.criterias[0].type === 'images'
                ? 'image__grid'
                : 'profile__grid',
            ]"
          >
            <div
              v-for="(item, id_2) in subgroup.criterias"
              :key="`${item.uuid}${id_2}`"
              :class="`${item.classnames}`"
              :style="{ order: item.order_position }"
              :id="item.uuid"
            >
              <text-field
                v-if="['text', 'number', 'rating'].includes(item.type)"
                v-model="item.value"
                :is-error="
                  $v.structureData.$each[id_0].subgroups.$each[id_1].criterias
                    .$each[id_2].$error
                "
                :stars-read-only="editIsDisabled(item.uuid) || !isEditable"
                :error-text="$t(`error.required_field`)"
                :title="item.title"
                :is-rate="item.type === 'rating'"
                :is-icon="item.type === 'text_icon'"
                :value-mask="item.mask"
                :is-required="item.validation.includes('required')"
                :is-integer="item.validation.includes('int')"
                :is-inactive="editIsDisabled(item.uuid) || !isEditable"
                icon-name="actual"
                :can-comment="canComment"
                :is-commentable="commentsVisible"
                :is-last-comment-visible="true"
                :uuid="item.uuid"
                :id="item.uuid"
              />
              <FileUpload
                v-else-if="['file', 'files'].includes(item.type)"
                :id="item.uuid"
                v-model="item.value"
                :is-error="
                  $v.structureData.$each[id_0].subgroups.$each[id_1].criterias
                    .$each[id_2].$error
                "
                :error-text="$t(`error.required_field`)"
                :title="item.title"
                :is-multiple="item.type == 'files'"
                :valid-formats="fileTypes(item.validation)"
                :max-size-k-b="fileSizeLimit(item.validation)"
                :is-download-only="!editIsDisabled(item.uuid) && !isEditable"
                :can-comment="canComment"
                :is-commentable="commentsVisible"
                :is-inactive="editIsDisabled(item.uuid) || !isEditable"
                :uuid="item.uuid"
                @uploadFiles="uploadFiles($event, item)"
                @dropError="item.error = false"
              />
              <PhotoUpload
                v-else-if="['images'].includes(item.type)"
                :id="item.uuid"
                :uuid="item.uuid"
                v-model="item.value"
                :is-error="
                  $v.structureData.$each[id_0].subgroups.$each[id_1].criterias
                    .$each[id_2].$error
                "
                :error-text="$t(`error.required_field`)"
                :photos-uploaded="item.value"
                :title="item.title"
                is-multiple
                :valid-formats="fileTypes(item.validation)"
                :can-comment="canComment"
                :is-commentable="commentsVisible"
                :is-disabled="editIsDisabled(item.uuid) || !isEditable"
                :is-inactive="editIsDisabled(item.uuid) || !isEditable"
                :max-size-k-b="fileSizeLimit(item.validation)"
                @uploadFiles="uploadFiles($event, item)"
              />
              <div
                :class="`${item.classnames}`"
                v-else-if="item.type === 'switcher'"
              >
                <Switcher
                  :uuid="item.uuid"
                  :can-comment="canComment"
                  :is-commentable="commentsVisible"
                  :is-inactive="editIsDisabled(item.uuid) || !isEditable"
                  v-model="item.value"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <LoaderContent />
    </template>
    <div class="no-print">
      <ButtonsBlock
        :buttons="buttonsArray"
        :disabledSign="!isUserHasEuKey"
        :disabledDownLoad="!isCertificate"
        :disabledAchive="!isAchive"
        @print="print()"
        @createReport="createDraft"
        @createAndSignReport="createAndSignReport"
        @saveAsDraft="editDraft"
        @signAndSend="signAndSendReport"
        @downloadPDF="downloadPDF()"
        @downloadCertificate="downloadCertificate()"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { requiredIf } from 'vuelidate/lib/validators';
import { getCategorizationFormStructure } from '@/api/services/hotel.js';
import FileUpload from '@/elements/FileUpload.vue';
import PhotoUpload from '@/elements/PhotoUpload.vue';
import Switcher from '@/elements/Switcher.vue';
import ButtonsBlock from '@/elements/ButtonsBlock.vue';
import LoaderContent from '@/elements/LoaderContent.vue';

export default {
  components: {
    FileUpload,
    PhotoUpload,
    Switcher,
    ButtonsBlock,
    LoaderContent,
  },

  data() {
    return {
      buttonsArray: [],
      commentsVisible: false,
      canComment: '',
      canEdit: '',
      voteSchedule: '',
      dataToSend: null,
      validationPassed: true,

      idx: 0,
      structure: null,
      isSaved: false,
    };
  },

  async created() {
    await this.getCategorizationStructure();

    this.$store.commit('SET_CURRENT_FORM_TYPE', 'categorization-hotel'); // for key-sign flow

    let categorizationRequestUUUID = this.$route.params.id;

    if (this.$route.params.activity !== 'create' && !!this.$route.params.id) {
      let categorizationRequestUUUID = this.$route.params.id;
      await this.$store.dispatch(
        'getCategorizationHotel',
        categorizationRequestUUUID
      );
      this.populateFormWithData();
    } else {
    }
    await this.statusModelHandler();

    if (this.commentsVisible) {
      await this.$store.dispatch(
        'getCommentsHotel',
        categorizationRequestUUUID
      );
    }
  },

  beforeDestroy() {
    this.$store.dispatch('clearStore'); // call mutation
  },

  watch: {
    isCategorisationCreate(val) {
      val && this.$router.push({ name: 'hotel_categorizations-list' });
      this.$store.commit('SET_CATEGORISATION_DRAFT_SAVED', false);
    },

    structure: {
      handler(arr) {
        arr.map((g, i) =>
          g.subgroups.map((s) =>
            s.criterias.map((c) => {
              if (!!c.sum_of.length) {
                let sum = 0;
                c.sum_of.map((k) => {
                  sum += !!this.fieldsObjForCalculate[k]
                    ? this.fieldsObjForCalculate[k]
                    : 0;
                });
                c.value = `${sum}`;
              }
            })
          )
        );
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('modal', ['getModalState']),
    ...mapGetters([
      'getCategorizationHotel',
      'getCommentsHotel',
      'getStatusModel',
      'isCategorizationDraftSaved',
      'isUserHasEuKey',
    ]),

    isCategorisationCreate() {
      return !this.getModalState && this.isCategorizationDraftSaved;
    },

    structureData() {
      return this.structure;
    },

    isEditable() {
      return !!Object.keys(this.$route.params).length
        ? this.$route.params.activity === 'edit' ||
            this.$route.params.activity === 'create'
        : true;
    },

    fieldsObjForCalculate() {
      let obj = {};

      this.structure?.map((g) =>
        g.subgroups.map((s) =>
          s.criterias.map((c) => {
            if (c.validation.includes('int')) {
              if (Number.isInteger(c.value)) {
                obj[c.uuid] = c.value;
              } else {
                obj[c.uuid] = !!c.value
                  ? parseInt(c.value.split(' ').join(''))
                  : 0;
              }
            }
          })
        )
      );

      return obj;
    },

    errorFieldId() {
      let res;
      this.structure.find((el) =>
        el.subgroups.find((s) =>
          s.criterias.find((c) => {
            if (
              c.validation.includes('required') &&
              (c.value === null || !Boolean(c.value.length)) &&
              c.type !== 'switcher'
            ) {
              return (res = c.uuid);
            }
          })
        )
      );

      return res;
    },

    prefilledCriteris() {
      if (this.$route.meta.status != 'create') {
        let prefilledCriteris = {};
        this.getCategorizationHotel?.criteria?.forEach((criteria) => {
          prefilledCriteris[criteria.categorization_criterion_uuid] = criteria;
        });
        return prefilledCriteris;
      } else return {};
    },

    prefilledCriterisUUID() {
      if (this.$route.meta.status != 'create') {
        return Object.keys(this.prefilledCriteris);
      } else return {};
    },

    preLoadedFiles() {
      if (this.$route.meta.status != 'create') {
        let preLoadedFiles = {};
        this.getCategorizationHotel.criteria.forEach((criteria) => {
          if (Array.isArray(criteria.value)) {
            this.$set(
              preLoadedFiles,
              criteria.categorization_criterion_uuid,
              criteria.value
            );
          }
        });
        return preLoadedFiles;
      } else return {};
    },

    preLoadedFilesUUID() {
      if (this.$route.meta.status != 'create') {
        return Object.keys(this.preLoadedFiles);
      } else return {};
    },

    isCertificate() {
      return !!this.getCategorizationHotel?.certificate;
    },

    isAchive() {
      return !!this.getCategorizationHotel?.archive;
    },
  },

  methods: {
    async getCategorizationStructure() {
      let data = { lang: 'uk' };
      if (this.$route.params.activity !== 'create' && !!this.$route.params.id) {
        data.uuid = this.$route.params.id;
      }
      try {
        let res;
        res = await getCategorizationFormStructure(data);
        if (res.status === 200) {
          this.structure = res.data.data.structure;
        }
      } catch (e) {
        console.log(
          '>>> error getCategorizationFormStructure',
          e.response.data.error
        );
      }
    },

    statusModelHandler() {
      this.getStatusModel.forEach((element) => {
        if (
          element.filing_status == this.getCategorizationHotel.filing_status &&
          element.review_status == this.getCategorizationHotel.review_status
        ) {
          this.commentsVisible = element.comments_visible;
          this.buttonsArray = element.buttons;
          this.canComment = element.can_comment;
          this.canEdit = element.edit;
          this.voteSchedule = element.schedule;
        }
      });
    },

    populateFormWithData() {
      this.structure.map((el) =>
        el.subgroups.map((s) =>
          s.criterias.map(async (s) => {
            if (s.uuid in this.prefilledCriteris) {
              if (Array.isArray(this.prefilledCriteris[s.uuid].value)) {
                let valueToPopulate = [];

                for (let file in this.prefilledCriteris[s.uuid].value) {
                  let convertedFile = await this.createFile(
                    this.prefilledCriteris[s.uuid].value[file].original_url,
                    this.prefilledCriteris[s.uuid].value[file].mime
                  );
                  valueToPopulate.push(convertedFile);
                }
                s.value = valueToPopulate;
              } else {
                s.value = this.prefilledCriteris[s.uuid].value;
              }
            }
          })
        )
      );
    },

    async createFile(url, type) {
      const response = await fetch(url);
      const data = await response.blob();
      const metadata = {
        type: type,
      };
      return new File([data], url.split('/').at(-1), metadata);
    },

    editIsDisabled(uuid) {
      let editIsDisabled = false;
      switch (this.canEdit) {
        case 'can_edit_all_fields':
          editIsDisabled = false;
          break;
        case 'not_allowed':
          editIsDisabled = true;
          break;
        case 'can_edit_commented_fields':
          this.getCommentsHotel[uuid]
            ? (editIsDisabled = false)
            : (editIsDisabled = true);
          break;
      }
      return editIsDisabled;
    },

    fileTypes(fileValidation) {
      let fileTypes = [];
      fileValidation.forEach((element) => {
        if (element.includes('mimes')) {
          fileTypes = element.split(':')[1].split(',');
        }
      });
      return fileTypes;
    },

    fileSizeLimit(fileValidation) {
      let fileSizeLimit = [];
      fileValidation.forEach((element) => {
        if (element.includes('max')) {
          fileSizeLimit = parseInt(element.split(':')[1]);
        }
      });
      return fileSizeLimit;
    },

    checkFilesIdentity(fileOne, fileTwo) {
      if (
        fileOne.size == fileTwo.size &&
        fileOne.original_url.split('/').at(-1) == fileTwo.name
      ) {
        return true;
      }
    },

    addToDataToSend(uuid, value) {
      this.dataToSend.append(`criterias[${this.idx}][uuid]`, uuid);
      this.dataToSend.append(`criterias[${this.idx}][value]`, value);
      this.idx += 1;
    },

    addFileToSend(uuid, value) {
      this.dataToSend.append(`criterias[${this.idx}][uuid]`, uuid);
      this.dataToSend.append(`criterias[${this.idx}][value]`, value);
      this.idx += 1;
    },

    addMultipleFilesToSend(uuid, values) {
      this.dataToSend.append(`criterias[${this.idx}][uuid]`, uuid);
      for (let i = 0; i < values.length; i++) {
        this.dataToSend.append(
          `criterias[${this.idx}][value][][attachment]`,
          values[i]
        );
      }
      this.idx += 1;
    },

    addDeleteCriteria(uuid) {
      this.dataToSend.append(`delete_criterias[]`, uuid);
    },

    addDeleteCriteriaAttachment(uuid) {
      this.dataToSend.append(`delete_attachments[]`, uuid);
    },

    createDraft() {
      this.gatherAllCriteriasCreate();
      this.$store.dispatch('addCategorizationRequestDraft', this.dataToSend);
    },

    async createAndSignReport() {
      this.gatherAllCriteriasCreate();
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        document
          .getElementById(this.errorFieldId)
          .scrollIntoView({ block: 'start', behavior: 'smooth' });
      } else {
        this.$store.dispatch(
          'createFullCategorizationRequest',
          this.dataToSend
        );
      }
    },

    editDraft() {
      this.gatherAllCriteriasEdit();

      this.$store.dispatch('editCategorizationRequest', this.dataToSend);
    },

    signAndSendReport() {
      this.gatherAllCriteriasEdit();
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        document
          .getElementById(this.errorFieldId)
          .scrollIntoView({ block: 'start', behavior: 'smooth' });
      } else {
        this.$store.commit(
          'SET_DATA_TO_SIGN',
          this.getCategorizationHotel.uuid
        );
        this.$store.dispatch(
          'signEditedCategorizationRequest',
          this.dataToSend
        );
      }
    },

    gatherAllCriteriasCreate() {

      this.dataToSend = new FormData();

      this.structure.map((el) =>
        el.subgroups.map((s) =>
          s.criterias.map((c) => {
            // Text and numbers
            if (['text', 'number', 'rating'].includes(c.type)) {
              if (c.value !== '' && c.value) {
                let val = c.value;
                if (
                  c.validation.includes('int') &&
                  !Number.isInteger(c.value)
                ) {
                  val = parseInt(c.value);
                }

                if (!!val) {
                  this.addToDataToSend(c.uuid, val);
                }
              }
            }
            // Single file
            if (c.type === 'file' && !!c.value.length) {
              this.addFileToSend(c.uuid, c.value[0]);
            }
            // Multiple files
            if (['files', 'images'].includes(c.type) && !!c.value.length) {
              this.addMultipleFilesToSend(c.uuid, c.value);
            }
            if (c.type == 'switcher') {
              this.addToDataToSend(c.uuid, c.value ? 1 : 0);
            }
          })
        )
      );
    },

    gatherAllCriteriasEdit() {
      this.dataToSend = new FormData();
      this.dataToSend.append(
        'categorization_application_uuid',
        this.getCategorizationHotel.uuid
      );

      this.structure.map((el) =>
        el.subgroups.map((s) =>
          s.criterias.map((c) => {
            // Text and numbers
            if (['text', 'number', 'rating'].includes(c.type)) {
              // Checking for prefilling
              if (this.prefilledCriterisUUID.includes(c.uuid)) {
                if (c.value === '') {
                  this.addDeleteCriteria(this.prefilledCriteris[c.uuid].uuid);
                } else {
                  let val = c.value;
                  if (
                    c.validation.includes('int') &&
                    !Number.isInteger(c.value)
                  ) {
                    val = parseInt(c.value);
                  }
                  this.addToDataToSend(c.uuid, val);
                }
              } else {
                if (!!c.value) {
                  let val =
                    c.validation.includes('int') && !Number.isInteger(c.value)
                      ? parseInt(c.value)
                      : c.value;

                  if (!!val) {
                    this.addToDataToSend(c.uuid, val);
                  }
                }
              }
            }
            // Single file
            if (c.type == 'file') {
              if (this.preLoadedFilesUUID.includes(c.uuid)) {
                if (!c.value[0]) {
                  this.addDeleteCriteriaAttachment(
                    this.preLoadedFiles[c.uuid][0].id
                  );
                } else {
                  let prefilledFileInSubmitted = false;
                  prefilledFileInSubmitted = this.checkFilesIdentity(
                    this.preLoadedFiles[c.uuid][0],
                    c.value[0]
                  );
                  if (!prefilledFileInSubmitted) {
                    this.addFileToSend(c.uuid, c.value[0]);
                  }
                }
              } else if (!!c.value?.length) {
                this.addFileToSend(c.uuid, c.value[0]);
              }
            }
            // Multiple files
            if (['files', 'images'].includes(c.type) && c.value) {
              if (this.preLoadedFilesUUID.includes(c.uuid)) {
                // Check for deletion
                this.preLoadedFiles[c.uuid].forEach((preloaded) => {
                  let prefilledFileInSubmitted = false;
                  c.value.forEach((submitting) => {
                    if (this.checkFilesIdentity(preloaded, submitting)) {
                      prefilledFileInSubmitted = true;
                      return;
                    }
                  });
                  if (!prefilledFileInSubmitted) {
                    this.addDeleteCriteriaAttachment(preloaded.id);
                  }
                });
                // Check for addition
                c.value.forEach((submitting) => {
                  let submittedFileInPreloaded = false;
                  this.preLoadedFiles[c.uuid].forEach((preloaded) => {
                    if (this.checkFilesIdentity(preloaded, submitting)) {
                      submittedFileInPreloaded = true;
                    }
                  });
                  if (!submittedFileInPreloaded) {
                    this.dataToSend.append(
                      `criterias[${this.idx}][uuid]`,
                      c.uuid
                    );
                    this.dataToSend.append(
                      `criterias[${this.idx}][value][][attachment]`,
                      submitting
                    );
                    this.idx++;
                    // this.addMultipleFilesToSend(c.uuid, submitting);
                  }
                });
              } else if (!!c.value?.length) {
                this.addMultipleFilesToSend(c.uuid, c.value);
              }
            }
            //switcher
            if (c.type == 'switcher') {
              this.addToDataToSend(c.uuid, c.value ? 1 : 0);
            }
          })
        )
      );
    },

    print() {
      window.print();
    },

    downloadCertificate() {
      this.$store.dispatch(
        'downloadFile',
        this.getCategorizationHotel.certificate.attachment.original_url
      );
    },

    downloadPDF() {
      this.$store.dispatch(
        'downloadFile',
        this.getCategorizationHotel.archive.original_url
      );
    },
  },

  validations() {
    return {
      structureData: {
        $each: {
          subgroups: {
            $each: {
              criterias: {
                $each: {
                  value: {
                    required: requiredIf((value) => {
                      let res = false;
                      if (value.type !== 'switcher') {
                        res = value.validation.includes('required');
                      }
                      return res;
                    }),
                  },
                },
              },
            },
          },
        },
      },
    };
  },
};
</script>

<style lang="sass" scoped>
.categorisation__wrapper, .profile__section
  display: flex
  flex-direction: column
</style>
